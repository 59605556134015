'use client'
import React, { createContext, useContext, useEffect, useRef } from 'react'
import { MobileDrawer } from '../MobileDrawer'
import { DesktopPopover } from '../DesktopPopover'
import { useDisclosure } from '@mantine/hooks'
import { Box } from '@mantine/core'
import { usePathname } from 'next/navigation'

interface Props {
  children: React.ReactNode
}

interface MenuContextProps {
  opened: boolean
  toggle: () => void
  close: () => void
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

export const MenuProvider: React.FC<Props> = ({ children }) => {
  const [opened, { toggle, close }] = useDisclosure(false)
  const pathname = usePathname()
  const prevPathnameRef = useRef(pathname)

  useEffect(() => {
    if (pathname !== prevPathnameRef.current && opened) {
      close()
    }

    prevPathnameRef.current = pathname
  }, [pathname, close, opened])

  return <MenuContext.Provider value={{ opened, toggle, close }}>{children}</MenuContext.Provider>
}

export const useMenuContext = () => {
  const context = useContext(MenuContext)

  if (context === undefined) {
    throw new Error(`useMenuContext must be used within a MenuProvider.`)
  }

  return context
}

export const Menu: React.FC<Props> = ({ children }) => {
  return (
    <MenuProvider>
      <Box style={{ pointerEvents: 'initial' }}>
        <MobileDrawer>{children}</MobileDrawer>
        <DesktopPopover>{children}</DesktopPopover>
      </Box>
    </MenuProvider>
  )
}
