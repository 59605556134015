'use client'

import { Card, Text, UnstyledButton } from '@mantine/core'
import Link from 'next/link'
import React from 'react'
import classes from './MenuLinkWithText.module.css'

interface Props {
  href?: string
  title: string
  subtitle: string
}

export const MenuLinkWithText: React.FC<Props> = ({ href, title, subtitle }) => {
  return (
    <UnstyledButton
      component={href ? Link : undefined}
      href={href ? href : (null as any)}
      className="mantine-active"
    >
      <Card className={classes.card}>
        <Text fw={700} c="white" size="md">
          {title}
        </Text>
        <Text fw={400} c="dimmed" size="sm">
          {subtitle}
        </Text>
      </Card>
    </UnstyledButton>
  )
}
