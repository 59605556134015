'use client'

import React from 'react'
import classes from './MenuLink.module.css'
import { Box, Text, UnstyledButton } from '@mantine/core'
import Link from 'next/link'
import { IconChevronRight } from '@tabler/icons-react'
import clsx from 'clsx'

interface CommonProps {
  children: React.ReactNode
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
}

interface LinkProps extends CommonProps {
  href: string
}

interface ButtonProps extends CommonProps {
  href?: never
}

type Props = LinkProps | ButtonProps

export const MenuLink: React.FC<Props> = ({ href, onClick, type, children }) => {
  const isLink = typeof href === 'string'

  if (isLink) {
    return (
      <UnstyledButton
        component={Link}
        href={href}
        className={clsx(classes.root, 'mantine-active')}
        onClick={onClick}
      >
        <Text fw={700} c="inherit">
          {children}
        </Text>
        <Box component={IconChevronRight} className={classes.icon} />
      </UnstyledButton>
    )
  }
  return (
    <UnstyledButton className={clsx(classes.root, 'mantine-active')} onClick={onClick} type={type}>
      <Text fw={700} c="inherit">
        {children}
      </Text>
      <Box component={IconChevronRight} className={classes.icon} />
    </UnstyledButton>
  )
}
