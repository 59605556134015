'use client'
import { Box, BoxProps } from '@mantine/core'
import React from 'react'

const SVG = () => {
  return (
    <svg
      id="a"
      height="100%"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="m20.1541,9.6728c1.7914,1.0343,1.7914,3.6201,0,4.6544L4.0308,23.636c-1.7915,1.0343-4.0308-.2586-4.0308-2.3273V2.6912C0,.6226,2.2393-.6703,4.0308.364l16.1232,9.3088Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconPlay = (props: BoxProps) => {
  return (
    <Box {...props}>
      <SVG />
    </Box>
  )
}
