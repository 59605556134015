'use client'
import { UnstyledButtonLink } from '@/modules/common/components/UnstyledButtonLink/UnstyledButtonLink'
import { IconPlay } from '@/modules/common/icons/IconPlay'
import { Avatar, Card, Group, Stack, Text } from '@mantine/core'
import { IconUser } from '@tabler/icons-react'
import React from 'react'
import classes from './CurrentUser.module.css'
import { truncateString } from '@/lib/utils/truncateString'
import { Setting } from '@/payload-types'
import { User } from 'lucia'

export const CurrentUserClient = ({ user, data }: { user: User; data: Setting['navigation'] }) => {
  return (
    <Stack gap={'sm'} style={{ position: 'relative' }}>
      <Group wrap="nowrap">
        <Avatar src={user?.imageUrl} size="lg">
          {user?.firstName ? user?.firstName[0].toUpperCase() : <IconUser />}
        </Avatar>
        <UnstyledButtonLink href="/account">
          <Stack gap={4}>
            <Text fw={700} c="white" lh={1} truncate="end" lineClamp={1}>
              {truncateString(`${user?.firstName} ${user?.lastName}`, 26)}
            </Text>
            <Text size="sm" c="dimmed" lh={1}>
              {user?.email ? truncateString(user?.email, 28) : null}
            </Text>
          </Stack>
        </UnstyledButtonLink>
      </Group>
      <UnstyledButtonLink href={'/collection'} className={'mantine-active'}>
        <Card className={classes.card} c="white" p="sm">
          <Group align="center" justify="center">
            <IconPlay h={20} />{' '}
            <Text fw={700} c="white">
              {data?.myCollectionLabel || 'My Collection'}
            </Text>
          </Group>
        </Card>
      </UnstyledButtonLink>
    </Stack>
  )
}
