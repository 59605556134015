'use client'
import { Anchor } from '@mantine/core'
import Link from 'next/link'
import { Setting } from '@/payload-types'
import React from 'react'

interface PolicyLinkProps {
  data: Setting['navigation']
}

export const PolicyLink = ({ data }: PolicyLinkProps) => {
  return (
    <Anchor
      component={Link}
      href={typeof data?.menuPolicyLink === 'object' ? data?.menuPolicyLink?.pathname || '/' : '/'}
      c="dimmed"
      underline="hover"
      size="sm"
      mr="auto"
      style={{ lineHeight: 1 }}
    >
      {data?.menuPolicyLabel}
    </Anchor>
  )
}
