'use client'
import { Anchor } from '@mantine/core'
import Link from 'next/link'
import { Setting } from '@/payload-types'
import React from 'react'

interface AgreementLinkProps {
  data: Setting['navigation']
}

export const AgreementLink = ({ data }: AgreementLinkProps) => {
  return (
    <Anchor
      component={Link}
      href={
        typeof data?.menuAgreementLink === 'object' ? data?.menuAgreementLink?.pathname || '/' : '/'
      }
      c="dimmed"
      underline="hover"
      size="sm"
      mr="auto"
      style={{ lineHeight: 1 }}
    >
      {data?.menuAgreementLabel}
    </Anchor>
  )
}
