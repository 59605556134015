'use client'
import React from 'react'
import { MenuLink } from '../MenuLink'
import { logout } from '@/modules/auth/actions'

export const LogoutButton = ({ children }: { children: React.ReactNode }) => {
  return (
    <MenuLink type="submit" onClick={() => logout()}>
      {children}
    </MenuLink>
  )
}
