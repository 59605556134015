'use client'
import { Box, BoxProps } from '@mantine/core'
import React from 'react'

const SVG = () => {
  return (
    <svg id="a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 280">
      <path d="m43.0827,163.7151c-11.3324,0-20.1273-2.372-26.3837-7.1147-6.2589-4.744-10.6069-11.1337-13.0427-19.1719-2.4396-8.0344-3.6563-16.996-3.6563-26.8774v-56.5214c0-10.8023,1.2167-20.2238,3.6563-28.262,2.4359-8.0344,6.7839-14.2594,13.0427-18.6751C22.9554,2.6795,31.7504.4717,43.0827.4717c10.0117,0,17.9508,1.847,23.815,5.5334,5.8604,3.6902,10.0781,8.9616,12.648,15.8107,2.5687,6.8515,3.8531,15.0877,3.8531,24.7034v12.648h-21.146v-11.2647c0-5.9281-.2957-11.1657-.8896-15.7117-.5927-4.5447-2.2078-8.0682-4.8417-10.573-2.6364-2.501-7.0495-3.7553-13.2407-3.7553-6.324,0-10.9377,1.3533-13.8346,4.0523-2.8995,2.7015-4.7765,6.522-5.6323,11.4627-.8583,4.9407-1.2844,10.7058-1.2844,17.2917v63.4394c0,8.0382.6578,14.396,1.976,19.071,1.3157,4.6788,3.4897,8.0043,6.522,9.9803,3.0298,1.9773,7.1147,2.9647,12.2533,2.9647,6.0584,0,10.4064-1.3495,13.044-4.051,2.6339-2.699,4.2791-6.423,4.9407-11.1657.6566-4.744.9874-10.2773.9874-16.6013v-11.8574h21.146v11.8574c0,9.7523-1.1854,18.348-3.5573,25.791-2.3707,7.4442-6.4568,13.2413-12.2521,17.3914-5.799,4.15-13.9675,6.2263-24.5067,6.2263Z" />
      <path d="m192.8868,190.9871l-1.7793-29.0514h-86.3647V1.855h22.3327v144.0715h45.4547V1.855h22.3327v144.0715h15.2167v45.0606h-17.1927Z" />
      <path d="m227.6677,161.9358V1.855h62.056v16.6013h-39.7234v52.9641h32.214v15.8107h-32.214v58.6954h40.1193v16.0093h-62.452Z" />
      <path d="m308.1031,161.9358V1.855h22.3327v69.1707h41.1067V1.855h22.3327v160.0808h-22.3327v-74.9027h-41.1067v74.9027h-22.3327Z" />
      <path d="m410.2759,161.9358L444.268,1.855h21.542l34.19,160.0808h-21.1473l-7.3114-40.3167h-32.61l-7.7074,40.3167h-20.9481Zm31.6201-56.3253h26.6807l-13.4387-72.1341-13.242,72.1341Z" />
      <path d="m112.177,248.2453c0,4.6813-.5614,8.9391-1.6828,12.7758-1.1215,3.8355-2.8744,7.1385-5.2552,9.9077-2.3833,2.7704-5.4431,4.8956-9.1822,6.3766-3.739,1.4811-8.2111,2.2229-13.4186,2.2229-5.0961,0-9.5067-.7418-13.2319-2.2229-3.7252-1.4811-6.7914-3.5999-9.201-6.3553-2.4096-2.7567-4.1964-6.0584-5.3592-9.9089-1.1641-3.8493-1.7455-8.1422-1.7455-12.8786,0-6.314,1.045-11.8047,3.1363-16.4723,2.0913-4.6663,5.3241-8.2812,9.7009-10.8424,4.3756-2.5624,9.9703-3.843,16.7843-3.843,6.923,0,12.5528,1.2881,16.887,3.8631,4.3342,2.5762,7.5119,6.205,9.5343,10.885,2.0211,4.68,3.0336,10.1783,3.0336,16.4923Zm-41.7934,0c0,3.6563.4148,6.8002,1.2455,9.4315s2.1464,4.6525,3.947,6.0646,4.1538,2.1189,7.062,2.1189c3.0185,0,5.4218-.7067,7.2086-2.1189,1.7856-1.4122,3.0737-3.4333,3.8631-6.0646s1.1841-5.7752,1.1841-9.4315c0-5.4832-.9135-9.8174-2.7416-13.0026s-4.972-4.7778-9.4303-4.7778c-2.9647,0-5.3529.7205-7.1673,2.1602-1.8144,1.441-3.1301,3.4897-3.9458,6.1486-.8182,2.6589-1.2255,5.8165-1.2255,9.4716Z" />
      <path d="m175.1991,278.6976h-16.4936v-24.7598h-19.0685v24.7598h-16.4936v-60.7379h16.4936v22.5169h19.0685v-22.5169h16.4936v60.7379Z" />
      <path d="m240.0488,278.6976h-16.4923v-47.3192h-11.5911c-.1942,2.2717-.4574,4.8267-.7894,7.666-.3333,2.8394-.7004,5.7464-1.1014,8.7236-.4022,2.9772-.8245,5.8366-1.2668,8.5795-.4436,2.7416-.9009,5.1374-1.3708,7.1861-.7205,3.3518-1.7805,6.2188-3.1789,8.6008s-3.3017,4.2102-5.7113,5.4832c-2.4096,1.2731-5.5121,1.9109-9.3062,1.9109-1.2192,0-2.3544-.0827-3.407-.2494-1.0525-.1654-2.0637-.3884-3.0323-.6641v-12.6718c.7481.2494,1.4397.4511,2.0775.6027.6365.1529,1.3006.2293,1.9936.2293.9135,0,1.7242-.2631,2.4309-.7894.7055-.5263,1.3708-1.6765,1.9936-3.4483.6228-1.773,1.2392-4.5009,1.8482-8.1848.2218-1.1904.5125-2.9697.8734-5.3379.3596-2.3682.7681-5.1725,1.2255-8.4128.4561-3.2403.9272-6.7851,1.4122-10.6357.4849-3.8493.9623-7.8515,1.4335-12.0065h41.9594v60.7379Z" />
      <path d="m290.4429,278.6976l-2.9922-11.3825h-19.7326l-3.0749,11.3825h-18.0298l19.8166-60.9872h21.8941l20.0647,60.9872h-17.9458Zm-6.3979-24.8437l-2.6176-9.9703c-.2769-1.0801-.6791-2.6238-1.2054-4.6324-.5263-2.0073-1.045-4.0849-1.5575-6.2313s-.9072-3.9119-1.1841-5.2965c-.2494,1.3846-.6027,3.095-1.0588,5.1299-.4574,2.0362-.9285,4.051-1.4134,6.0458-.4849,1.9936-.9072,3.6551-1.2668,4.9845l-2.6176,9.9703h12.9212Z" />
      <path d="m314.9533,217.9597h15.2894v27.7933c0,1.2468-.015,2.5549-.0426,3.9257s-.0689,2.7216-.124,4.051c-.0564,1.3295-.1253,2.59-.208,3.7804-.0827,1.1916-.1804,2.2429-.2907,3.1576h.3321l25.0104-42.7081h18.8192v60.7379h-15.3295v-26.2972c0-1.1352.02-2.4233.0614-3.8643.0413-1.4397.0977-2.9208.1667-4.4445.0689-1.5224.1516-2.991.2494-4.4044.0965-1.4122.2005-2.6439.312-3.6964h-.3747l-25.0091,42.7068h-18.8618v-60.7379Zm51.889-20.3566c-.3321,3.1025-1.233,5.8441-2.7003,8.2261-1.4685,2.382-3.6563,4.2452-6.5633,5.5872-2.9083,1.3445-6.6761,2.0161-11.301,2.0161-4.4858,0-8.1835-.5827-11.0918-1.7455s-5.1098-2.9145-6.6059-5.2552c-1.4949-2.3406-2.382-5.2828-2.6589-8.8288h13.5439c.1667,3.0198.8308,5.0622,1.9936,6.1285,1.1641,1.0663,2.7692,1.5989,4.8191,1.5989,1.8282,0,3.3656-.5538,4.6124-1.6615,1.2455-1.1077,1.9798-3.1288,2.2016-6.0659h13.7507Z" />
      <path d="m438.9213,278.6976h-16.4936v-24.7598h-19.0685v24.7598h-16.4936v-60.7379h16.4936v22.5169h19.0685v-22.5169h16.4936v60.7379Z" />
    </svg>
  )
}

export const IconBrandLogo = (props: BoxProps) => {
  return (
    <Box {...props}>
      <SVG />
    </Box>
  )
}
