'use client'

import React from 'react'
import { Box, Burger, Button, Overlay, Popover, Portal, Transition } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import classes from './DesktopPopover.module.css'
import { useMenuContext } from '../Menu/Menu'

interface Props {
  children: React.ReactNode
}

export const DesktopPopover: React.FC<Props> = ({ children }) => {
  const { opened, toggle } = useMenuContext()
  const isDesktop = useMediaQuery('(min-width: 36em)')
  return (
    <Box>
      <Popover
        opened={opened}
        trapFocus={true}
        /**
         * if we don't use 'isDesktop', clicking anywhere on the MobileMenu will cause this onChange to fire,
         * closing the drawer unintentionally
         */
        onChange={isDesktop ? toggle : undefined}
        position="left-start"
        radius="md"
        closeOnClickOutside={true}
        classNames={{
          dropdown: classes.dropdown,
        }}
      >
        <Popover.Target>
          <Burger onClick={toggle} visibleFrom="xs" opened={opened} />
        </Popover.Target>
        <Popover.Dropdown visibleFrom="xs" w={340} p="lg">
          {children}
        </Popover.Dropdown>
      </Popover>
      <Portal>
        <Transition mounted={opened} duration={240}>
          {(styles) => <Overlay visibleFrom="xs" w="100vw" style={styles} />}
        </Transition>
      </Portal>
    </Box>
  )
}
