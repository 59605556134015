'use client'
import React, { useEffect, useState } from 'react'
import classes from './HeaderBackground.module.css'
import { Box } from '@mantine/core'
import clsx from 'clsx'
import { useWindowScroll } from '@mantine/hooks'

export const HeaderBackground = ({ children }: { children: React.ReactNode }) => {
  const [scroll] = useWindowScroll()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    if (scroll.y > 50 && isScrolled === false) {
      setIsScrolled(true)
    } else if (scroll.y <= 50 && isScrolled === true) {
      setIsScrolled(false)
    }
  }, [scroll.y, isScrolled])

  return (
    <Box component="header" className={clsx(classes.root, { [classes.scrolled]: isScrolled })}>
      {children}
    </Box>
  )
}
