'use client'
import { Box, Burger, Drawer } from '@mantine/core'
import React from 'react'
import classes from './MobileDrawer.module.css'
import { useMediaQuery } from '@mantine/hooks'
import { useMenuContext } from '../Menu/Menu'

interface Props {
  children: React.ReactNode
}

export const MobileDrawer: React.FC<Props> = ({ children }) => {
  const { opened, close, toggle } = useMenuContext()
  const isMobile = useMediaQuery('(max-width: 36em)')
  return (
    <>
      <Burger onClick={toggle} opened={opened} size="md" hiddenFrom="xs" />
      <Drawer
        opened={opened}
        onClose={isMobile ? close : () => {}}
        hiddenFrom="xs"
        withCloseButton={false}
        classNames={{ body: classes.body, content: classes.content }}
      >
        <Box className={classes.contentWrapper}>{children}</Box>
      </Drawer>
    </>
  )
}
