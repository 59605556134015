'use client'
import { UnstyledButton, UnstyledButtonProps } from '@mantine/core'
import Link from 'next/link'
import React from 'react'

interface UnstyledButtonLinkProps extends UnstyledButtonProps {
  children?: React.ReactElement
  href: string
}

export const UnstyledButtonLink: React.FC<UnstyledButtonLinkProps> = ({
  href,
  children,
  ...rest
}) => {
  return (
    <UnstyledButton component={Link} href={href} {...rest}>
      {children}
    </UnstyledButton>
  )
}
